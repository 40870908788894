const excuses = {
  "ed0": "Aquarium lek",
  "ed1": "Medicijnen ophalen",
  "ed2": "Ziek",
  "ed3": "Kind(eren) wegbrengen",
  "ed4": "huisarts",
  "ed5": "Ritje teststraat",
  "ed6": "Controle ziekenhuis",
  "ed7": "Mijn teams doet raar keuken met auto ok zo aan...",
  "ed8": "Wij zijn vandaag in Eindhoven",
  "ed9": "De trein sloeg station Nijmegen Goffert over...",
  "ed10": "Verslapen",
  "ed11": "Mijn kettingslot is vastgevroren"
};

export default excuses
